@import "variables";
@import "~base/login";

.login-page {
    margin-bottom: rem(90px);

    &.request-password {
        min-height: rem(435px);
        padding-top: rem(115px);

        .form-group {
            margin-bottom: rem(10px);

            @include media-breakpoint-down(md) {
                margin-bottom: rem(15px);
            }
        }

        .request-password-title {
            @include media-breakpoint-up(md) {
                font-size: rem(32px);
            }
        }

        .enter-new-container {
            padding-top: 30px;

            .reset-container {
                padding-top: 34px;
            }
        }

        .subtitle {
            color: $medium-gray;

            @include media-breakpoint-down(sm) {
                font-size: rem(14px);
            }
        }

        @include media-breakpoint-up(md) {
            min-height: rem(560px);
        }
    }

    &.email-sent {
        .card {
            max-width: rem(600px);
        }

        .request-password-body {
            color: $medium-gray;
        }
    }

    .card {
        border: 0;
        max-width: rem(430px);

        .card-body {
            padding: 0;

            .title {
                &.login-title {
                    text-align: center;
                }

                @include media-breakpoint-down(md) {
                    font-size: rem(24px);
                }
            }

            .login-form-nav {
                margin-bottom: 0;

                .tab-content {
                    .tab-pane {
                        &#login-section {
                            margin-top: rem(150px);

                            #login-form-email {
                                border-bottom: 0.0625rem solid $input-border-color;
                                margin-bottom: rem(20px);
                            }

                            @include media-breakpoint-down(md) {
                                margin-top: rem(15px);
                            }
                        }

                        &#register-section {
                            margin-top: rem(35px);

                            .required-text {
                                font-size: $font-size-xs;
                                font-style: italic;
                                letter-spacing: -0.25px;
                                line-height: rem(36px);
                                text-align: center;
                            }

                            .content-text {
                                font-size: rem(10px);
                                letter-spacing: -0.25px;
                                line-height: rem(15px);
                                margin-bottom: rem(12px);
                            }

                            .additional-terms-section {
                                font-size: rem(8px);
                                letter-spacing: -0.25px;
                                line-height: rem(12px);
                                padding-top: rem(12px);

                                a {
                                    color: $black !important;
                                }
                            }
                        }

                        form {
                            &.login,
                            &.registration {
                                margin-bottom: rem(24px);
                                padding: 0 rem(15px);

                                .btn {
                                    margin-top: rem(24px);
                                    padding: 0;
                                    height: rem(44px);
                                    line-height: rem(44px);

                                    svg {
                                        margin: 0 rem(6px) rem(2px) 0;
                                    }
                                }
                            }
                        }

                        .loyalty-icons {
                            align-items: center;
                            display: flex;
                            justify-content: space-evenly;
                            margin: rem(24px) 0;

                            .loyalty-icon {
                                max-width: rem(75px);
                                text-align: justify;

                                img {
                                    display: block;
                                    margin: 0 auto;
                                }

                                p {
                                    font-size: rem(10px);
                                    letter-spacing: -0.25px;
                                    line-height: rem(14px);
                                    margin: 0;
                                    padding-top: rem(8px);
                                    text-align: center;
                                }
                            }
                        }

                        .joined-pacsun {
                            padding-left: rem(15px);

                            .primary-text-line {
                                font-size: rem(16px);
                                font-weight: $font-weight-medium;
                                line-height: rem(24px);
                            }

                            .secondary-text-line {
                                color: $medium-gray;
                                display: flex;
                                font-size: $font-size-xs;
                                line-height: rem(18px);
                                padding-top: rem(6px);
                            }

                            @include media-breakpoint-up(md) {
                                max-width: rem(345px);
                            }
                        }
                    }
                }

                .nav-tabs {
                    border: 0;
                    display: block;
                    padding: rem(10px) 0 0 rem(15px);

                    li {
                        display: block;

                        a {
                            color: $black;
                            float: left;
                            font-size: $font-size-xs;
                            font-weight: $font-weight-semibold;
                            line-height: rem(18px);
                            padding: 0;
                            text-decoration: underline;
                            text-transform: uppercase;

                            &.active {
                                display: none;
                            }
                        }
                    }

                    @include media-breakpoint-up(md) {
                        bottom: rem(2px);
                        padding: rem(20px) 0 0;
                        position: absolute;
                        right: rem(16px);
                    }
                }
            }
        }
    }

    .password-reset {
        color: $medium-gray !important;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        line-height: rem(15px);
    }

    .remember-me {
        span {
            display: block;
            padding-top: rem(3px);
        }
    }

    .custom-checkbox label {
        line-height: rem(18px);

        .content-asset {
            font-size: rem(10px);
            letter-spacing: -0.25px;
            line-height: rem(15px);
            padding-top: rem(1px);
        }
    }

    .category-checkbox-selections {
        justify-content: center;

        .label {
            color: $medium-gray;
            font-size: rem(12px);
            letter-spacing: -0.25px;
            line-height: rem(18px);
            padding: rem(14px) 0 rem(16px) 0;

            @include media-breakpoint-up(md) {
                padding-top: rem(2px);
            }
        }

        label {
            color: $medium-gray;
            font-size: rem(14px);
            letter-spacing: -0.25px;
            line-height: rem(21px);
            padding-right: rem(16px);

            &::before,
            &::after {
                top: rem(2px);
            }
        }

        .login-page {
            margin-bottom: rem(6px);

            &.last {
                label {
                    padding-right: 0;
                }
            }
        }

        input:checked + label {
            color: $primary;
        }

        input:checked ~ .custom-control-label::after {
            top: rem(1px);
        }
    }

    .form-group {
        margin-bottom: rem(10px);

        &.required {
            .form-control-label {
                &::after {
                    content: "";
                }
            }
        }

        &.float-label {
            .form-control {
                height: rem(42px);
                padding: rem(20px) 0.625rem rem(15px);
            }

            input,
            textarea,
            select {
                ~ label {
                    color: $medium-gray;
                    font-size: $font-size-sm;
                    top: rem(12px);
                }
            }

            input:focus,
            input.text-entered,
            textarea:focus,
            textarea.text-entered,
            select.text-entered {
                ~ label {
                    font-size: rem(10px);
                    top: rem(-4px);
                }
            }
        }

        input.form-control {
            ~ label {
                padding-left: 0;
            }
        }

        .invalid-feedback {
            font-size: 75%;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: rem(22px);
        }
    }

    .returnCheckout {
        font-weight: $font-weight-medium;
        padding-right: rem(15px);
        padding-top: rem(15px);
        text-align: right;
        text-decoration: underline;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-xs;
            line-height: rem(18px);
            padding-bottom: rem(15px);
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: rem(15px);
    }

    @include media-breakpoint-up(md) {
        .column-left {
            padding-right: rem(8px);
        }

        .column-right {
            padding-left: rem(8px);
        }
    }
}

.alert-danger {
    font-size: $font-size-xs;
    color: $danger;
}

.login-banner {
    background: none;
}
